import { addSearchToUrl } from "@deltagreen/utils"

export const LINKS = {
  home: "/",
  authLogin: "/auth/login",
  authForbidden: "/auth/forbidden",
  authGoodbye: "/auth/goodbye",

  invertersDashboard: `/inverters/dashboard`,
  invertersList: `/inverters/list`,
  inverterDetail: (deviceId: string) => `/inverters/detail/${deviceId}`,
  inverterControlPlanPredictions: (deviceId: string, controlPlanId: string) =>
    `/inverters/detail/${deviceId}/control-plan/${controlPlanId}/predictions`,

  heatPumpsDashboard: `/heat-pumps/dashboard`,
  heatPumpsList: `/heat-pumps/list`,
  heatPumpDetail: (deviceId: string) => `/heat-pumps/detail/${deviceId}`,

  electricVehiclesDashboard: `/electric-vehicles/dashboard`,
  electricVehiclesList: `/electric-vehicles/list`,
  electricVehicleDetail: (deviceId: string) => `/electric-vehicles/detail/${deviceId}`,
  electricVehicleDetailEdit: (deviceId: string) => `/electric-vehicles/detail/${deviceId}/edit`,
  electricVehicleNew: `/electric-vehicles/new`,

  linkBoxList: `/link-boxes/list`,
  linkBoxOverview: `/link-boxes/overview`,
  linkBoxDetail: (boxId: string) => `/link-boxes/detail/${boxId}`,
  linkBoxFlashFirmware: `/link-boxes/flash-firmware`,
  linkBoxFirmwares: `/link-boxes/firmwares`,

  otherDevicesDashboard: `/other-devices/dashboard`,
  otherDevicesList: `/other-devices/list`,
  otherDeviceDetail: (deviceId: string) => `/other-devices/detail/${deviceId}`,
  deltaNew: `/other-devices/delta/new`,
  deltaEdit: (deviceId: string) => `/other-devices/delta/${deviceId}/edit`,

  systemQueues: "/system/queues",
  systemMqtt: "/system/mqtt",
  systemOte: "/system/ote",
  systemMaintenances: "/system/maintenance",
  systemMaintenanceDetail: (id: string) => `/system/maintenance/${id}`,

  proteusUsers: "/users/list",
  proteusUserDetail: (id: string) => `/users/detail/${id}`,
  operatorUsers: "/users-operator/list",
  operatorUserDetail: (id: string) => `/users-operator/detail/${id}`,

  households: "/households/list",
  householdDetail: (id: string) => `/households/detail/${id}`,

  blocks: "/blocks/list",
  blockDetail: (id: string) => `/blocks/detail/${id}`,

  mockedInverters: "/mocked-inverters/list",
  mockedInverterDetail: (id: string) => `/mocked-inverters/detail/${id}`,

  tenants: "/tenants/list",
  tenantDetail: (id: string) => `/tenants/detail/${id}`,

  portalImpersonate: `/portal/impersonate`,
}

export const EXTERNAL_LINKS = {
  batteryDeviceGrafanaDashboard: (deviceId: string, environment: "dev" | "prod") =>
    `https://grafana.svc.deltagreen.cz/d/Z4UK-M04k?orgId=1&var-inverter_id=${deviceId}&var-database=TimescaleDB%20Flex%20${environment}`,
  electricVehicleGrafanaDashboard: (deviceId: string, environment: "dev" | "prod") =>
    `https://grafana.svc.deltagreen.cz/d/QdCcHKLVk?var-electric_vehicle_id=${deviceId}&var-database=TimescaleDB%20Flex%20${environment}`,
  deltaGrafanaDashboard: (deviceId: string) =>
    `https://grafana.svc.deltagreen.cz/d/_TnCWx14z?orgId=1&var-delta_id=${deviceId}`,
  balancingGrafanaDashboard: (environment: "dev" | "prod", inverterIds: string[], from: Date, to: Date) =>
    addSearchToUrl("https://grafana.svc.deltagreen.cz/d/d24f1ab1-e159-42b2-930d-d5cb48d8dfa5/balancing-debug-wip", {
      orgId: 1,
      to: to.valueOf(),
      from: from.valueOf(),
      "var-database": `TimescaleDB Flex ${environment}`,
      "var-inverter_id": inverterIds,
    }),
  linkBoxGrafanaDashboard: (linkBoxId: string, environment: "dev" | "prod") =>
    addSearchToUrl("https://grafana.svc.deltagreen.cz/d/eb6ea8d5-9474-4d18-bbba-0967479a98d9/link-box-detail", {
      orgId: 1,
      "var-database": `TimescaleDB Flex ${environment}`,
      "var-link_box_id": linkBoxId,
    }),
  linkBoxHandlerGrafanaDashboard: (linkBoxId: string) =>
    addSearchToUrl("https://grafana.svc.deltagreen.cz/d/ecacbeb4-25d2-4ee3-b38a-c80cfe72f604/link-box-handler-logs", {
      orgId: 1,
      // "var_database"
      "var-link_box_id": linkBoxId,
    }),
}
